<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off">
      <div class="container">
        <div class="">
          <div class="card mb-g">
            <div class="card-body pb-0 px-4">
              <div class="d-flex flex-row pb-3 pt-2  border-top-0 border-left-0 border-right-0">
                <h5 class="mb-0 flex-1 text-dark fw-500">
                  Cập nhật thông tin đăng ký của bạn
                </h5>
              </div>
              <div class="alert alert-success" v-if="user.is_confirm === 1">
                Bạn đã gửi thông tin. Đang chờ xét duyệt. Chào mừng bạn đã đến với Edutalk!
              </div>
              <div class="alert alert-danger" v-if="user.is_confirm === 3">
                Xác minh của bạn bị từ chối với lý do {{ user.reason }}
              </div>
              <div class="pb-3 pt-2 border-top-0 border-left-0 border-right-0 text-muted">
                <div class="form-row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label">Họ và tên <span class="text-danger">(*)</span></label>
                    <validation-provider vid="name" name="Tên" rules="required" v-slot="{ errors, classes }">
                      <input type="text" class="form-control" id="name" placeholder="Họ và tên"
                             autocomplete="false" v-model="user.name" :class="classes">
                      <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label" for="email">Email <span class="text-danger">(*)</span></label>
                    <ValidationProvider vid="email" name="Email" rules="required"
                                        v-slot="{ errors, classes }">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="email">@</span>
                        </div>
                        <input type="email" ref="email" v-model="user.email" name="email"
                               :class="classes" class="form-control" id="email-input"
                               placeholder="Nhập email của bạn" aria-describedby="email">
                        <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label">Số điện thoại <span class="text-danger">(*)</span></label>
                    <ValidationProvider vid="phone" name="Số điện thoại"
                                        :rules="{required: true, min: 10, regex: /((09|03|07|08|05)+([0-9]{8})\b)/g }"
                                        v-slot="{ errors, classes }">
                      <div class="input-group">
                        <the-mask v-model="user.phone" ref="phone" :class="classes"
                                  placeholder="Nhập số điện thoại"
                                  class="form-control" id="phone-input" :mask="['##########']"/>
                        <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label">Vị trí hiện tại</label>
                    <input type="text" class="form-control" id="account_type"
                           :value="user.account_type"
                           placeholder="Vị trí/Chức vụ" disabled>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label">Khu vực làm việc <span class="text-danger">(*)</span></label>
                    <validation-provider vid="area_id" name="Khu vực làm việc" rules="required"
                                         v-slot="{ errors, classes}">
                      <edutalk-select v-model="user.area_id" class="w-100"
                                      clearable filterable
                                      placeholder="Chọn khu vực làm việc"
                                      :class="classes"
                                      @change="chooseArea"
                      >
                        <edutalk-option
                            v-for="item in areas"
                            :key="item.id"
                            :label="item.name "
                            :value="item.id"
                        >
                        </edutalk-option>
                      </edutalk-select>
                      <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>

                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label">Cơ chế thu nhập<span class="text-danger">(*)</span></label>
                    <validation-provider vid="area_id" name="Cơ chế thu nhập" rules="required"
                                         v-slot="{ errors, classes}">
                      <edutalk-select v-model="user.profile.salary_mechanism_id" class="w-100"
                                      clearable filterable
                                      placeholder="Chọn cơ chế thu nhập"
                                      :class="classes"
                      >
                        <edutalk-option
                            v-for="item in mechanisms"
                            :key="item.id"
                            :label="item.name "
                            :value="item.id"
                        >
                        </edutalk-option>
                      </edutalk-select>
                      <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>

                  </div>
                  <div class="col-md-4 mb-3" v-if="user.area_id">
                    <label class="form-label">Văn phòng làm việc <span class="text-danger">(*)</span></label>
                    <validation-provider vid="branch_id" name="Văn phòng làm việc" rules="required"
                                         v-slot="{ errors, classes}">
                      <edutalk-select v-model="user.branch_id" class="w-100" clearable filterable
                                      placeholder="Chọn cơ sở làm việc" :class="classes">
                        <edutalk-option
                            v-for="item in branches"
                            :key="item.id"
                            :label="item.name_alias ? item.name_alias : item.name "
                            :value="item.id"
                        >
                        </edutalk-option>
                      </edutalk-select>
                      <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>

                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label">Địa chỉ <span class="text-danger">(*)</span></label>
                    <validation-provider vid="address" name="Địa chỉ" rules="required" v-slot="{ errors, classes}">
                      <input v-model="user.profile.address" type="text" :class="classes" class="form-control"
                             id="address"
                             placeholder="Nhập địa chỉ thường trú">
                      <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label">Giới tính <span class="text-danger">(*)</span></label>
                    <ValidationProvider vid="gender" name="Giới tính" rules="required"
                                        v-slot="{ errors, classes }">
                      <edutalk-select id="gender" v-model="user.profile.gender" class="w-100"
                                      placeholder="Chọn giới tính" :class="classes" ref="gender" name="gender">
                        <edutalk-option
                            v-for="item in [{id: 1, name: 'Nam'}, { id: 0, name: 'Nữ'}]"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name">
                        </edutalk-option>
                      </edutalk-select>
                      <div class="invalid-feedback" data-field="gender" data-validator="notEmpty">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label">Ngày sinh <span class="text-danger">(*)</span></label>
                    <ValidationProvider vid="birthday" name="Ngày sinh" rules="required"
                                        v-slot="{ errors, classes }">
                      <el-date-picker id="birthDay"
                                      format="dd-MM-yyyy"
                                      ref="birthday"
                                      name="birthday"
                                      :class="classes"
                                      value-format="dd-MM-yyyy"
                                      type="date"
                                      placeholder="Ngày sinh"
                                      v-mask="'##-##-####'"
                                      v-model="user.profile.birthday"
                                      :picker-options="pickerOptions"
                                      default-value="2000-10-10"
                                      class="w-100"
                      ></el-date-picker>
                      <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--eslint-disable-->
        </div>
        <div class="row mb-lg-0 mb-4">
          <div class="col-md-6">
            <div class="card mb-g show">
              <div class="row row-grid no-gutters dropdown-menu-animated">
                <div class="col-12">
                  <div class="p-3">
                    <h5 class="mb-0 flex-1 text-dark fw-500">
                      Tài khoản ngân hàng
                    </h5>
                  </div>
                </div>
                <div class="col-12">
                  <div class="p-3">
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label class="form-label">Chủ tài khoản</label>
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="user.owner_name"
                                 placeholder="Đầy đủ họ tên, không dấu" autocomplete="false">
                          <div class="input-group-append">
                                            <span class="input-group-text">
                                                <i class="far fa-exclamation-triangle color-danger-900"></i> Tài khoản ngân hàng nhập phải là chính chủ </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-12 mb-3">
                        <label class="form-label">Chọn ngân hàng</label>
                        <edutalk-select v-model="user.ngan_hang" @change="changeBank" class="w-100" clearable filterable
                                        placeholder="Chọn ngân hàng">
                          <edutalk-option
                              v-for="item in banks"
                              :key="item.id"
                              :label="item.name"
                              :value="item.id"
                          >
                          </edutalk-option>
                        </edutalk-select>
                      </div>
                      <div v-if="hideProvince" class="col-md-6 mb-3">
                        <label class="form-label">Tỉnh thành</label>
                        <edutalk-select v-model="user.province" @change="changeProvince" class="w-100" clearable
                                        filterable placeholder="Chọn tỉnh thành">
                          <edutalk-option
                              v-for="item in provinces"
                              :key="item"
                              :label="item"
                              :value="item">
                          </edutalk-option>
                        </edutalk-select>
                      </div>
                      <div v-if="hideProvince" class="col-md-6 mb-3">
                        <label class="form-label">Chi nhánh</label>
                        <edutalk-select v-model="user.chi_nhanh" class="w-100" clearable filterable
                                        placeholder="Chọn chi nhánh">
                          <edutalk-option
                              v-for="item in agency"
                              :key="item"
                              :label="item"
                              :value="item">
                          </edutalk-option>
                        </edutalk-select>
                      </div>
                      <div class="col-md-12 mb-3">
                        <label class="form-label">Số tài khoản</label>
                        <input type="text" class="form-control" placeholder="Số tài khoản ngân hàng" v-model="user.stk"
                               autocomplete="false">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card mb-g" style="height: 314px; overflow: auto">
              <div class="card-body pb-0 px-4">
                <div class="d-flex flex-row pb-3 pt-2  border-top-0 border-left-0 border-right-0">
                  <h5 class="mb-0 flex-1 text-dark fw-500">
                    Xác minh danh tính <span class="text-danger">(*)</span>
                  </h5>
                </div>
                <div class="pb-3 pt-2 border-top-0 border-left-0 border-right-0 text-muted">
                  <div class="row">
                    <div v-if="verifying" class="content_verify">
                      <validation-provider vid="verify_images" name="Xác minh danh tính" rules="required"
                                           v-slot="{ errors, classes}">
                        <input v-model="verify_images" type="hidden" :class="classes" class="form-control"
                               id="verify_images"
                               placeholder="Nhập địa chỉ thường trú">
                        <div class="invalid-feedback" data-field="name" data-validator="notEmpty">
                          {{ errors[0] }}
                        </div>
                      </validation-provider>
                      <h6 class="text-center">Tải lên thẻ căn cước và ảnh cá nhân</h6>
                      <div class="row front_image">
                        <div class="col-sm-12">
                          <el-upload
                              action=""
                              accept="image/jpeg,image/gif,image/png"
                              :class="{ hide: hideInputUpload }"
                              list-type="picture-card"
                              ref="upload"
                              :on-change="handleChange"
                              :file-list="fileList"
                              :auto-upload="false"
                              :on-preview="handlePictureCardPreview"
                              :on-remove="handleRemove">
                            <i class="el-icon-upload"></i>
                            Tải lên <b class="focus-guide">{{ textGuideUpload }}</b>
                          </el-upload>

                          <!--eslint-disable-->
                          <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                          </el-dialog>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 verifying-class" v-else-if="!verifying && !reject">
                      <i :class="{ 'el-icon-success': verified, 'el-icon-s-promotion': !verified }"></i>
                      {{
                        !verified ?
                            'Gửi thông tin xác minh danh tính thành công.' :
                            'Đã được xác minh danh tính'
                      }}
                    </div>
                    <div class="col-sm-6 verifying-class" v-else-if="!verifying && reject">
                      <i class="el-icon-warning text-warning"></i>
                      Thông tin xác minh của bạn bị từ chối. <span class="text-info reject" @click="reUpload">Upload lại thông tin ngay</span>
                    </div>
                  </div>
                </div>
                <p class="text-muted fs-xs opacity-70 text-center"><i class="el-icon-info"
                                                                      style="margin-right: 10px"></i>Thông tin này chỉ
                  được dùng để xác minh danh tính và sẽ được Edutalk giữ an toàn.</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <button class="btn btn-primary waves-effect waves-themed text-left" :disabled="loading" type="submit"
                    v-if="user.is_confirm === 3 || user.is_confirm === 0 || !user.is_confirm">Cập nhật thông tin
            </button>
          </div>
          <div class="col-6">
            <router-link class="btn btn-primary waves-effect waves-themed float-right" :to="{name : 'login'}">Thoát
            </router-link>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import {POST_VERIFY_IDENTITY} from "@/core/services/store/profile.module";
import provinces from '@/core/config/provinces';
import agency from '@/core/config/agency';
import banks from '@/core/config/banks';
import _ from "lodash-es";
import {
  GET_AREA_BY_ID,
  GET_AREAS,
  GET_BRANCHES,
  GET_BRANCHES_BY_AREAS
} from "@/core/services/store/center/branch.module";
import {GET_USER_BY_ID, SALE_UPDATE_PROFILE} from "@/core/services/store/profile.module";
import {mechanisms, mechanisms2, part_time_fixed_salary , full_time_fixed_salary} from "@/core/config/mechanism";

export default {
  name: "XacMinhDanhTinh",
  components: {},
  props: {
    confirming: Boolean,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loading: false,
      id: '',
      user: {
        profile: {
          gender: '',
          birthday: '',
          salary_mechanism_id: '',
          account_type: {
            id: '',
            name: ''
          }
        },
        chi_nhanh: "",
        ngan_hang: "",
        stk: "",
        province: "",
        owner_name: "",
        user_positions: {},
        phone: '',
        branch_id: '',
        area_id: '',
        email: '',
        is_confirm: 2,
        reason: '',
      },
      plurality: null,
      options: [
        {name: 'Nam', id: 1},
        {name: 'Nữ', id: 0},
      ],
      branches: [],
      areas: [],
      mechanisms: [],
      concurrentOption: [
        {id: 'TPNS', name: 'TP Nhân sự'},
        {id: 'TPOnline', name: 'TP Online'},
        {id: 'TVV', name: 'Tư vấn viên'},
        {id: 'SLX', name: 'SLX'},
        {id: 'TPTT', name: 'TP Truyền thông'},
      ],
      /* xác minh ảnh căn cước */
      dialogImageUrl: '',
      dialogVisible: false,
      hideInputUpload: false,
      verifying: true,
      verified: false,
      reject: false,
      textGuideUpload: 'mặt trước thẻ căn cước',
      fileList: [],
      URLUpload: POST_VERIFY_IDENTITY,
      imageUrl: false,
      listOder: ['mặt trước thẻ căn cước', 'mặt sau thẻ căn cước', 'ảnh cá nhân '],
      stt: 0,
      /* Thông tin ngân hàng */
      provinces: provinces,
      agency: [],
      banks: banks,
      hideProvince: false,
      verify_images: ''
    }
  },
  async created() {
    this.getAreas();
    this.id = this.$route.params.id;
    await this.getUser();
    this.getBranchesByArea({area_id: this.user.area_id});
  },
  mounted() {
    if (this.errors) {
      this.$refs.form.setErrors(this.errors);
    }
  },
  methods: {
    summit() {
      let formData = new FormData();
      if (this.fileList.length < 3) {
        this.$refs.form.setErrors({verify_images: 'Bạn chưa đăng đủ ảnh xác minh danh tính'});
        return;
      }
      let bankInformation = {
        ngan_hang: this.user.ngan_hang,
        stk: this.user.stk,
        owner_name: this.user.owner_name,
      }
      let checkBankArr = Object.values(bankInformation).filter((item => item === ''));
      if (checkBankArr.length === 1 || checkBankArr.length === 2) {
        this.$message({
          type: 'warning',
          message: 'Yêu cầu điền đủ thông tin các mục "Chủ tài khoản, Tên ngân hàng, Số tài khoản"',
          showClose: true
        });
        return;
      }
      this.loading = true;
      for (const key in this.user) {
        if (key !== 'profile') {
          formData.append(key, this.user[key]);
        } else {
          for (const k in this.user.profile) {
            formData.append(k, this.user.profile[k]);
          }
        }
      }
      this.fileList.forEach((file, key) => {
        formData.append(`file_${key}`, file.raw)
      });
      this.$store.dispatch(SALE_UPDATE_PROFILE, {
        id: this.id,
        formData: formData
      }).then((res) => {
        if (!res.error) {
          this.user.is_confirm = 1;
          this.$message({
            type: 'success',
            message: 'Cập nhật thông tin thành công!',
            showClose: true
          });
          this.$refs.form.reset();
        }
      }).catch((res) => {
        if (res.data?.data?.message_validate_form) {
          this.$refs.form.setErrors(res.data.data.message_validate_form);
        }
        if (res.data?.message) {
          this.$notify({
            type: 'error',
            message: res.data.message,
            title: 'Thất bại'
          });
        }
      }).finally(() => {
        this.loading = false
      });
    },
    getBranches() {
      this.$store.dispatch(GET_BRANCHES).then(res => {
        this.branches = res.data;
      });
    },
    getAreas() {
      this.$store.dispatch(GET_AREAS).then(res => {
        this.areas = res.data;
      });
    },
    chooseArea(id) {
      let params = {area_id: id}
      this.user.branch_id = '';
      this.user.profile.salary_mechanism_id = '';
      this.mechanisms = [];
      this.selectMechanismByArea(id);
      this.getBranchesByArea(params);
    },
    selectMechanismByArea(area_id) {
      if (!area_id) {
        return;
      }
      this.$store.dispatch(GET_AREA_BY_ID, area_id).then(res => {
        // 29: ứng viên
        if(this.user.profile.account_type.id == 29) {
          // nếu type ứng viên là Công tác viên, học sinh, sinh viên
          if([6, 0, 1].includes(this.user.profile.candidate.type)) {
            this.mechanisms = mechanisms2;
            return;
          }

          // nếu type ứng viên là full-time
          if(this.user.profile.candidate.type == 4) {
            this.mechanisms = full_time_fixed_salary;
            return;
          }

          // nếu type ứng viên là part-time
          if(this.user.profile.candidate.type == 5) {
            this.mechanisms = part_time_fixed_salary;
            return;
          }
        }

        
        if(this.user.profile.account_type.id == 9 && res.data.type_id == 2) {
          this.mechanisms = mechanisms2;
          return;
        }
        
        if(res.data.type_id == 1) {
          this.mechanisms = mechanisms2;
          return;
        } else {
          this.mechanisms = mechanisms;
        }
      });

    },
    getBranchesByArea(params) {
      this.$store.dispatch(GET_BRANCHES_BY_AREAS, params).then(res => {
        this.branches = res.data;

      });
    },
    async getUser() {
      await this.$store.dispatch(GET_USER_BY_ID, this.id).then((res) => {
        this.user.name = res.data.name;
        this.user.email = res.data.email;
        this.user.phone = res.data.phone;
        this.user.reason = res.data.reason;
        this.user.profile.gender = res.data.profile.gender;
        this.user.account_type = res.data.profile.account_type.name;
        this.user.profile.address = res.data.profile.address;
        this.user.profile.candidate = res.data.profile.candidate;
        this.user.profile.khuvuc_id = res.data.profile.khuvuc_id;
        this.user.profile.birthday = res.data.profile.birthday ? res.data.profile.birthday.split("-").reverse().join("-") : '';
        this.user.profile.salary_mechanism_id = res.data.profile.salary_mechanism_id > 0 ? res.data.profile.salary_mechanism_id : ''
        this.user.is_confirm = res.data.is_confirm;
        this.user.branch_id = res.data.profile.chi_nhanh_id ? Number(res.data.profile.chi_nhanh_id) : '';
        this.user.area_id = res.data.profile.khuvuc_id ? Number(res.data.profile.khuvuc_id) : '';
        this.user.profile.account_type.id = res.data.profile.account_type.id;
        if (res.data.bank_accounts.length > 0) {
          let bankAccount = res.data.bank_accounts[0];
          this.user.owner_name = bankAccount.owner_name;
          this.user.ngan_hang = bankAccount.ngan_hang;
          this.user.province = bankAccount.province;
          this.user.chi_nhanh = bankAccount.chi_nhanh;
          this.user.stk = bankAccount.stk;
        }
        this.selectMechanismByArea(this.user.profile.khuvuc_id);
      });
    },
    getErrors(scope) {
      try {
        const errors = this.$refs[scope].errors
        let parsed = []
        Object.keys(errors).map((key) => {
          parsed.push(errors[key][0])
        });
        return parsed
      } catch {
        return false
      }
    },
    /* Đăng ảnh căn cước */
    handleRemove(file) {
      this.listOder.unshift(file.type_upload);
      this.hideInputUpload = false;
      this.textGuideUpload = this.listOder[0];

      let index = this.fileList.findIndex((n, key) => {
        return n.url === file.url;
      });
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
      if (file.type_upload) {
        this.stt--;
      }
      if (this.stt >= 3) {
        this.hideInputUpload = true
      }
      this.verify_images = this.fileList.length;

    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange(file) {
      const isImage2M = file.size / 1024 / 1024 < 2;

      let lastUpdate = _.remove(this.listOder, function (n, key) {
        return key === 0;
      });
      if (this.listOder.length > 0) {
        this.textGuideUpload = this.listOder[0];
      }
      if (lastUpdate.length > 0) {
        file.type_upload = lastUpdate[0];
      }
      this.fileList.push(file);

      this.stt++;

      if (this.stt >= 3) {
        this.hideInputUpload = true
      }
      this.verify_images = this.fileList.length;
      if (!isImage2M) {
        this.$message.error('File upload không được phép > 2Mb');
        this.handleRemove(file);
      }
    },
    reUpload() {
      this.verifying = true;
    },
    /* Thông tin ngân hàng */
    changeBank() {
      this.hideProvince = false;
      if (this.user.ngan_hang === 'Agribank') {
        this.hideProvince = true;
        this.user.province = '';
        this.user.chi_nhanh = '';
      }
    },
    changeProvince() {
      this.agency = agency[this.user.province];
      this.user.chi_nhanh = '';
    },
  },

};
</script>

<style>
.focus-guide, .el-icon-upload {
  padding: 10px;
}

.alert-success {
  color: #fff;
  background: #28a745;
  border-color: #23923d;
}
</style>
